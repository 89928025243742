import React from "react";
import Header from "../components/Header";
import Footer from "./components/Footer";
import Hero from "./components/Hero";
import Logos from "./components/Logos";
import SplittingAnimation from "./components/SplittingAnimation";
import { Datasets } from "./components/Datasets";
import Carousel from "./components/Carousel";
import Simulation from "./components/Simulation";
import Pricing from "./components/Pricing";
import Faq from "./components/Faq";

export default function NewLandingPage() {
    return (
        <div className="apply-tailwind bg-neutral-50">
            <div className="max-w-7xl mx-auto">
                <Header />
                <div className="px-4">
                    <Hero />
                    <Logos />
                    <Datasets />
                    <SplittingAnimation />
                    <Carousel />
                    <Simulation />
                    <Pricing />
                    <Faq />
                </div>
            </div>
            <Footer />
        </div>
    );
}
