import React from "react";

const Footer = () => {
    return (
        <footer className="bg-base-200 border-t border-base-content/10">
            <div className="max-w-7xl mx-auto px-4 py-24">
                <div className=" flex lg:items-start md:flex-row md:flex-nowrap flex-wrap flex-col">
                    <div className="w-64 flex-shrink-0 md:mx-0 mx-auto text-center md:text-left">
                        <a
                            href="/#"
                            aria-current="page"
                            className="flex gap-2 justify-center md:justify-start items-center"
                        >
                            <img
                                src="/logo192.png"
                                alt="Redistricter logo"
                                className="w-6 h-6"
                                width={24}
                                height={24}
                            />
                            <strong className="font-extrabold tracking-tight text-base md:text-lg flex">
                                Redistricter
                                <span className="text-[7px] font-extrabold mb-auto -mt-1.5 ml-[3px]">
                                    TM
                                </span>
                            </strong>
                        </a>

                        <p className="mt-3 text-sm text-base-content/80">
                            The most powerful mapping tool for visualizing the
                            data that shapes America
                        </p>
                        <p className="mt-3 text-sm text-base-content/60">
                            By Colin Miller<br/>© 2024 Redistricter, LLC.
                        </p>
                        <p className="mt-3 text-sm text-base-content/60">
                            5900 Balcones Drive, STE 4000, Austin, TX 78731
                        </p>
                    </div>
                    <div className="flex-grow flex flex-wrap justify-center -mb-10 md:mt-0 mt-10 text-center">
                        <div className="lg:w-1/3 md:w-1/2 w-full px-4">
                            <div className="footer-title font-semibold text-base-content tracking-widest text-sm md:text-left mb-3">
                                PAGES
                            </div>

                            <div className="flex flex-col justify-center items-center md:items-start gap-2 mb-10 text-sm">
								<a href="https://docs.redistricter.com" className="link link-hover">Docs</a>
								<a href="/map/OK/demo/blockgroups" className="link link-hover">Demo</a>
								{/* <a href="/changelog" className="link link-hover">Changelog</a> */}
                                <a href="/account" className="link link-hover">Account Settings</a>
                                <a href="/datadictionary" className="link link-hover">Data Dictionary</a>
								<a href="/uk" className="link link-hover">UK</a>
                                <a href="/about" className="link link-hover">About</a>
                                <a href="/tos" className="link link-hover">Terms of service</a>
                                {/* <a href="https://geoshaper.com" className="link link-hover">
                                    Geoshaper
                                </a> */}
                                <a href="https://colindm.com" className="link link-hover">
                                    Personal Site
                                </a>
                                <a href="https://redistricter.redbubble.com" className="link link-hover">
                                    Merchandise
                                </a>
                            </div>
                        </div>

						<div className="lg:w-1/3 md:w-1/2 w-full px-4">
                            <div className="footer-title font-semibold text-base-content tracking-widest text-sm md:text-left mb-3">
                                CONTACT
                            </div>

                            <div className="flex flex-col justify-center items-center md:items-start gap-2 mb-10 text-sm">
                                <a
                                    href="mailto:colin@redistricter.com"
                                    target="_blank"
                                    className="link link-hover"
                                    aria-label="Contact Support"
                                    rel="noreferrer"
                                >
                                    Email
                                </a>
                                <a
                                    href="https://x.com/redistricter"
                                    target="_blank"
                                    className="link link-hover"
                                    rel="noreferrer"
                                >
                                    Twitter
                                </a>
                                {/* <a
                                    href="https://discord.gg/26xSwKtgM6"
                                    target="_blank"
                                    className="link link-hover"
                                    rel="noreferrer"
                                >
                                    Discord
                                </a> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
