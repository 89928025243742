import React, { useEffect, useRef } from "react";
// @ts-ignore
import { ReactComponent as SvgMap } from "../map.svg";
import { FiArrowRight } from "react-icons/fi";
import "../css/animate.css";
import "../css/svg.css";
import "../css/demoButton.css";

export default function Hero() {
    const svgRef = useRef(null);
    const heroRef = useRef(null);

    useEffect(() => {
        const svgElement = svgRef.current;
        if (!svgElement) return;
    
        // @ts-ignore
        const paths = svgElement.querySelectorAll("path");
        const colors = [
            "#FE7E7F",
            "#7E7DFE",
            "#FDE0E0",
            "#ACACF9"
        ];
    
        // Defer the SVG manipulation to avoid blocking the initial page load
        const timeoutId = setTimeout(() => {
            paths.forEach((path: any) => {
                // Randomly assign color
                const color = colors[Math.floor(Math.random() * colors.length)];
                path.style.stroke = color;
                path.style.fill = color;
            });
        }, 0); // Timeout can be adjusted based on when you want the update to occur
    
        return () => clearTimeout(timeoutId);
    }, []); // Ensure this runs only once after the initial render

    useEffect(() => {
        const svgElement = svgRef.current;
        const heroElement = heroRef.current;
        if (!svgElement || !heroElement) return;
        // @ts-ignore
        const paths = svgElement.querySelectorAll("path");

        let frameId: any = null;
        function updatePathStyles(x: any, y: any) {
            if (frameId !== null) {
                cancelAnimationFrame(frameId);
            }
            frameId = requestAnimationFrame(() => {
                paths.forEach((path: any) => {
                    const rect = path.getBoundingClientRect();
                    const centerX = rect.left + rect.width / 2;
                    const centerY = rect.top + rect.height / 2;
                    const distance = Math.sqrt(
                        Math.pow(centerX - x, 2) + Math.pow(centerY - y, 2)
                    );

                    if (distance < 70) {
                        path.classList.add("hover-effect");
                        path.classList.remove("default-effect");
                    } else {
                        path.classList.add("default-effect");
                        path.classList.remove("hover-effect");
                    }
                });
            });
        }

        function handleMouseMove(event: any) {
            updatePathStyles(event.clientX, event.clientY);
        }

        function clearHoverEffects() {
            paths.forEach((path: any) => {
                path.classList.remove("hover-effect");
                path.classList.add("default-effect");
            });
        }

        // @ts-ignore
        heroElement.addEventListener("mousemove", handleMouseMove);
        // @ts-ignore
        heroElement.addEventListener("mouseleave", clearHoverEffects);

        return () => {
            // @ts-ignore
            heroElement.removeEventListener("mousemove", handleMouseMove);
            // @ts-ignore
            heroElement.removeEventListener("mouseleave", clearHoverEffects);
        };
    }, []);
    return (
        <div
            ref={heroRef}
            className="landingPageSection py-0"
            // style={{ height: "calc(fit-content-10px)" }}
        >
            <div className="relative bg-cover bg-center bg-no-repeat h-[70vh] flex items-center justify-center bg-black -mt-[5px]">
                <img
                    src="/landing-page/nyc3.jpg"
                    alt="Background"
                    className="absolute inset-0 w-full h-full object-cover opacity-60"
                />
                <SvgMap
                    ref={svgRef}
                    className="map-svg absolute inset-0 w-full h-full object-cover"
                />
                <div className="flex flex-col w-fit mx-auto pointer-events-none z-10 px-5 text-white">
                    <p className="text-center font-bold mb-5 text-4xl lg:text-5xl">
                        Political analysis for experts
                    </p>
                    <p className="text-center font-medium mb-5 text-xl lg:text-2xl">
                        The most powerful mapping tool for visualizing the data
                        that shapes America
                    </p>
                    <div className="flex items-center justify-center gap-4 pb-4">
                        <button
                            className="apply-tailwind hidden md:visible lg:flex button-gradient items-center gap-2 text-lg font-medium py-3 px-4 rounded-[5px] pointer-events-auto bg-gradient-to-br from-blue-800/90 to-red-800/90"
                            onClick={(e) => {
                                e.preventDefault();
                                window.open(
                                    "/map/OK/demo/blockgroups",
                                    "_blank"
                                );
                            }}
                        >
                            Try the demo
                            <FiArrowRight
                                style={{
                                    strokeWidth: 3,
                                    height: "auto",
                                    width: 20,
                                    transitionDuration: "0.5s"
                                }}
                            />
                        </button>
                        <button
                            className="apply-tailwind text-lg font-medium py-2 lg:py-3 px-4 rounded-[5px] bg-gray-700/50 hover:bg-gray-700/80 transition-all duration-300 pointer-events-auto"
                            onClick={() =>
                                window.open(
                                    "https://docs.redistricter.com",
                                    "_blank"
                                )
                            }
                        >
                            Read the docs
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
