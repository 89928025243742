import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";
import "firebase/compat/analytics";
import { ObjectHash } from "./types";

const fbConfig = {
    apiKey: "AIzaSyCr3joFm3aEXzH7fvXcQnDtdS30wSHPS7k",
    // authDomain: "redistricter.firebaseapp.com",
    authDomain: "redistricter.com",
    projectId: "redistricter",
    storageBucket: "redistricter.appspot.com",
    messagingSenderId: "81864993225",
    appId: "1:81864993225:web:d749fed4d103bb2a8b6018"
};

firebase.initializeApp(fbConfig);

const fbService: any = {
    firebase,
    auth: firebase.auth(),
    firestore: firebase.firestore(),
    analytics: firebase.analytics()
};

export const getCurrentUser = (): ObjectHash | null => {
    return fbService.auth.currentUser || null;
};

export const signedIn = (): boolean => Boolean(getCurrentUser()?.uid);

export const getFirebase = (): any => fbService;
