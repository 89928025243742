import React, { useEffect, useState } from "react";
import styles from "./account.module.css";
import { FiEdit2 } from "react-icons/fi";

import { useDisclosure } from "@mantine/hooks";
import { TextInput, Modal, Group, Button } from "@mantine/core";
import { useForm } from "@mantine/form";
import { doc, getFirestore, setDoc } from "firebase/firestore";
import { v4 as uuidv4 } from "uuid";
import { getFirebase } from "../lib/firebase";
import useUserRole from "../hooks/useUserRole";
// import useAppSettings from "../hooks/useAppSettings";

const db = getFirestore();

export function Account() {
    const { auth, firestore } = getFirebase();
    // const { getStripeRole } = useAppSettings();
    // const stripeRole = getStripeRole();
    const { role: stripeRole } = useUserRole(auth.currentUser?.uid, auth.currentUser?.email);
    const [openedEmail, { open: openEmail, close: closeEmail }] = useDisclosure(false);
    // const [openedPassword, { open: openPassword, close: closePassword }] = useDisclosure(false);

    const [openedNewsletters, { open: openNewsletters, close: closeNewsletters }] = useDisclosure(false);
    const [loading, setLoading] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [newslettersDocRef, setNewslettersDocRef] = useState(false);

    const formEmail = useForm({
        initialValues: {
            email: auth.currentUser.email
        },
        validate: {
            email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email")
        }
    });

    // function callCreateCheckoutSession(
    //   userId,
    //   isCommercial,
    //   userCount,
    //   success_url = 'https://redistricter.com',
    //   cancel_url = 'https://redistricter.com/account'
    // ) {
    //   console.log(JSON.stringify(userId));
    //   console.log(JSON.stringify(isCommercial));
    //   console.log(JSON.stringify(userCount));
    //   console.log(JSON.stringify(success_url));
    //   console.log(JSON.stringify(cancel_url));
    //   const requestOptions = {
    //     method: 'POST',
    //     headers: { 'Content-Type': 'application/json' },
    //     body: JSON.stringify({ userId, isCommercial, userCount, success_url, cancel_url })
    //   };

    //   // const functionUrl = 'https://us-central1-redistricter.cloudfunctions.net/createCheckoutSession';
    //   const functionUrl = 'http://127.0.0.1:5001/redistricter/us-central1/createCheckoutSession';

    //   // Make the request
    //   fetch(functionUrl, requestOptions)
    //     .then(response => response.json())
    //     .then(data => console.log(data))
    //     .catch(error => console.error('Error:', error));
    // }

    // const formPassword = useForm({
    //     initialValues: {
    //         password: '',
    //     },
    //     validate: {
    //         password: (value) => (value.length >= 6 ? null : 'Password should be at least 6 characters long'),
    //     },
    // });

    const handleEmailChange = async (values) => {
        try {
            await auth.currentUser.updateEmail(values.email);
            closeEmail();
        } catch (error) {
            // alert(error.message);
        }
    };

    const handleNewsletterChange = async (values) => {
        setSubmitting(true);
        try {
            const emailId = uuidv4();
            await setDoc(doc(db, "emails", emailId), {
                email: values.email,
                uid: auth.currentUser.uid,
                timestamp: new Date().toLocaleString("en-US", {
                    timeZone: "America/Chicago"
                })
            });

            closeNewsletters();
        } catch (error) {
            // alert(error.message);
        } finally {
            setSubmitting(false);
        }
    };

    // const handlePasswordChange = async (values) => {
    //     try {
    //         await auth.currentUser.updatePassword(values.password);
    //         closePassword();
    //     } catch (error) {
    //         // alert(error.message);
    //     }
    // };

    const getModalOpened = () => {
        if (openedEmail) {
            return openedEmail;
        }

        if (openedNewsletters) {
            return openedNewsletters;
        }

        return false;
    };

    const getModalCloseHandler = () => {
        if (openedEmail) {
            return closeEmail;
        }

        if (openedNewsletters) {
            return closeNewsletters;
        }

        return () => {};
    };

    const getModalSubmitHandler = () => {
        if (openedEmail) {
            return handleEmailChange;
        }

        if (openedNewsletters) {
            return handleNewsletterChange;
        }

        return () => {};
    };

    const getModalTextContent = () => {
        if (openedEmail) {
            return {
                label: "New Email",
                placeholder: "Enter new email",
                submitButton: "Change Email"
            };
        }

        if (openedNewsletters) {
            return {
                label: "Email",
                placeholder: "Enter your email",
                submitButton: "Subscribe"
            };
        }
        return { label: "", placeholder: "", submitButton: "" };
    };

    const handleNewsletterUnsubscribe = async () => {
        // const = await firestore.collection("emails").where('email', '==', auth.currentUser.uid).
        setSubmitting(true);
        Promise.all(
            newslettersDocRef.docs.map(async (doc) => {
                if (doc.data().uid === auth.currentUser.uid) {
                    doc.ref.delete();
                }
            })
        ).finally(() => setSubmitting(false));
    };

    useEffect(() => {
        async function test() {
            setLoading(true);
            try {
                const res = await firestore.collection("emails").where("uid", "==", auth.currentUser.uid).get();
                setNewslettersDocRef(res);
            } catch (error) {
                console.log(error.message);
            } finally {
                setLoading(false);
            }
        }
        test();
    }, [submitting, firestore, auth]);

    return (
        <>
            <Modal opened={getModalOpened()} onClose={getModalCloseHandler()} xOffset={"-20vw"} centered>
                <form onSubmit={formEmail.onSubmit(getModalSubmitHandler())}>
                    <TextInput
                        label={getModalTextContent().label}
                        placeholder={getModalTextContent().placeholder}
                        {...formEmail.getInputProps("email")}
                    />

                    <Group position="right" mt="md">
                        <Button type="submit" loading={submitting} disabled={submitting}>
                            {getModalTextContent().submitButton}
                        </Button>
                    </Group>
                </form>
            </Modal>

            {/* <Modal opened={openedPassword} onClose={closePassword} xOffset={'-20vw'} centered>
                <form onSubmit={formPassword.onSubmit(handlePasswordChange)}>
                    <TextInput
                        type="password"
                        label="New Password"
                        placeholder="Enter new password"
                        {...formPassword.getInputProps('password')}
                    />

                    <Group position="right" mt="md">
                        <Button type="submit">Change Password</Button>
                    </Group>
                </form>
            </Modal> */}

            <div className={styles.main}>
                <h1 className={styles.title}>Account</h1>
                <h3>Subscription tier: {stripeRole}</h3>
                <div className={styles.tableContainer}>
                    <table className={styles.table}>
                        <tbody>
                            <tr>
                                <td className={styles.tableHeader}>Email</td>
                                <td className={styles.tableData}>
                                    <p>{auth.currentUser.email}</p>
                                    <FiEdit2 className={styles.editIcon} onClick={openEmail} />
                                </td>
                            </tr>
                            {/* <tr>
                                <td className={styles.tableHeader}>Password</td>
                                <td className={styles.tableData}>
                                    <p>********</p>
                                    <FiEdit2
                                        className={styles.editIcon}
                                        onClick={openPassword}
                                    />
                                </td>
                            </tr> */}
                            {/* <tr>
                <td className={styles.tableHeader}>Account type</td>
                <td
                  className={styles.tableData}
                  style={{
                    display: "inline-flex",
                    flexDirection: "column"
                  }}
                >
                  {!stripeRole ? (
                    <>
                      <p>No subscription recorded</p>
                      <p className={styles.subtext}>
                        No subscription was detected for this account. If you
                        think this is incorrect{" "}
                        <a href="mailto:colin@redistricter.com">
                          contact me to get this resolved
                        </a>
                        . Include your account information in the email.
                      </p>
                    </>
                  ) : (
                    <p>{stripeRole}</p>
                  )}
                  {(stripeRole && stripeRole !== "Organization") && (
                    <>
                      <p className={styles.subtext}>
                        Individual accounts are{" "}
                        <a href="/tos">
                          not licensed 
                        </a>
                        {" "}to use Redistricter for commercial, government, or other official purposes.
                      </p>
                      <p className={styles.subtext} style={{ marginTop: '5px' }}>
                        If you plan to use Redistricter for this{" "}
                        <a href="/signup/commercial">upgrade to a commercial-tier subscription</a>.
                      </p>
                    </>
                  )}
                </td>
              </tr> */}
                            <tr>
                                <td className={styles.tableHeader}>Billing</td>
                                <td className={styles.tableData}>
                                    <p>
                                        <a href="https://billing.stripe.com/p/login/fZeaF78DFaqQ5R69AA">
                                            Manage billing
                                        </a>
                                    </p>
                                </td>
                            </tr>
                            {stripeRole !== "Commercial" && stripeRole !== "Admin" && (
                                <tr>
                                    <td className={styles.tableHeader}>Subscription Plan</td>
                                    <td className={styles.tableData}>
                                        <p>
                                            <a href="/signup/commercial">Upgrade to a commercial subscription</a>
                                        </p>
                                    </td>
                                </tr>
                            )}
                            <tr>
                                <td className={styles.tableHeader}>Yearly Subscription</td>
                                <td className={styles.tableData}>
                                    <p>
                                        <a href="/switchToYearly">Switch to yearly billing</a>
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td className={styles.tableHeader}>Redistricter Newsletter</td>
                                <td className={styles.tableData} style={{ paddingBottom: 15 }}>
                                    {!newslettersDocRef.empty ? (
                                        <Button
                                            variant="outline"
                                            color="gray"
                                            onClick={handleNewsletterUnsubscribe}
                                            loading={loading}
                                            disabled={loading}
                                        >
                                            Unsubscribe
                                        </Button>
                                    ) : (
                                        <Button onClick={openNewsletters} loading={loading} disabled={loading}>
                                            Subscribe
                                        </Button>
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td className={styles.tableHeader}>Contact</td>
                                <td className={styles.tableData}>
                                    <p>
                                        If you were redirected to this page after attempting to open a Redistricter map
                                        your account may not have <a href="/signup">paid for access yet</a>. If this was
                                        done in error please <a href="mailto:colin@redistricter.com">contact me</a> and
                                        I will get this resolved as soon as possible.
                                    </p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                {/* <p>Organization management</p> */}
            </div>
        </>
    );
}
