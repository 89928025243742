import React, { useState, useEffect, useRef } from "react";
// @ts-ignore
import { ReactComponent as SvgMap } from "../dc2.svg";
import "../css/splittingAnimation.css";
import { FiGrid } from "react-icons/fi";

function contextMenu(forceHover: boolean) {
    return (
        <div
            className="p-1 bg-gray-100 shadow-lg rounded-[0.4rem] w-48 font-medium text-sm text-gray-900 overflow-visible"
            style={{
                fontFamily:
                    "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
            }}
        >
            <button
                className={`flex justify-between items-center w-full p-2 rounded-[0.3rem] ${
                    forceHover ? "bg-gray-200" : "hover:bg-gray-200"
                }`}
            >
                <span>Split precinct</span>
                <FiGrid />
            </button>
        </div>
    );
}

const DEFAULT_CURSOR_POSITION = {
    top: "-3rem",
    left: "calc(66%)",
    transform: "scale(0.8)",
    opacity: 0
};

export default function SplittingAnimation() {
    const [animate, setAnimate] = useState(false);
    const [position, setPosition] = useState(DEFAULT_CURSOR_POSITION);
    const [showContextMenu, setShowContextMenu] = useState(false);
    const [forceContextMenuHover, setForceContextMenuHover] = useState(false);
    const [splitPath, setSplitPath] = useState(false);
    const svgRef = useRef(null);

    const handleStartAnimation = () => {
        setAnimate(true);
        // Move cursor to the cube
        setTimeout(
            () =>
                setPosition({
                    top: "calc(50% - 2rem)",
                    left: "calc(50% - 2rem)",
                    transform: "scale(1)",
                    opacity: 1
                }),
            1000
        );
        // Move across to click split precinct
        setTimeout(
            () =>
                setPosition({
                    top: "calc(50% - 3rem)",
                    left: "calc(50% + 1rem)",
                    transform: "scale(1)",
                    opacity: 1
                }),
            2000
        );
        setTimeout(() => setShowContextMenu(true), 1500);
        setTimeout(() => setForceContextMenuHover(true), 2100);
        // Trigger cube splitting when context menu goes away
        setTimeout(() => {
            setShowContextMenu(false);
            setForceContextMenuHover(false);
            setSplitPath(true);
        }, 3000);
        // Return cursor to original position
        setTimeout(() => setAnimate(false), 5500);
        setTimeout(() => setSplitPath(false), 6000);
    };

    useEffect(() => {
        handleStartAnimation();
        const interval = setInterval(() => {
            handleStartAnimation();
        }, 6500)
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (!animate) {
            setPosition(DEFAULT_CURSOR_POSITION);
        }
    }, [animate]);

    return (
        //  bg-gradient-to-br from-indigo-700 to-blue-600 text-white
        <div className="landingPageSection h-[70vh] px-5 pb-0 sm:pb-auto flex flex-col overflow-hidden bg-gradient-to-br from-indigo-700 to-blue-600 text-white">
            <p className="landingPageTitle text-center">
                Street-level data only a click away
            </p>
            {/* <p className="text-center text-xl lg:text-2xl text-white pt-5 pb-10 max-w-[800px] font-medium mx-auto">
                From counties to cul-de-sacs Redistricter's data comes in every
                size
            </p> */}
            <p className="landingPageSubtitle max-w-[700px] mx-auto text-center">
                Redistricter's data is available at the smallest units so you don't have to choose between the big picture and the fine details.
            </p>
            <div className="relative w-full flex-1 mb-5 text-black">
                <div className="rounded-[0.5rem] overflow-hidden relative bg-cover bg-center bg-no-repeat h-full flex items-center justify-center z-0">
                    <img
                        src="/landing-page/dc3.jpeg"
                        alt="Background"
                        className="absolute inset-0 w-full h-full object-cover bg-[#F7F7F7]"
                        style={{
                            transform: "scale(1.05)"
                        }}
                        // opacity-50
                    />
                    <SvgMap
                        ref={svgRef}
                        className={`animate-map-svg absolute inset-0 w-full h-full object-cover ${
                            splitPath ? "show-blocks" : "hide-blocks"
                        }`}
                        style={{ transform: "scale(1.05)" }}
                    />
                </div>
                <div className="z-20 overflow-visible">
                    {showContextMenu && (
                        <div
                            className="absolute h-16 top-4 left-20"
                            style={{
                                top: "50%",
                                left: "50%",
                                transform: "translate(-10%, -100%)"
                            }}
                        >
                            {contextMenu(forceContextMenuHover)}
                        </div>
                    )}
                    <div
                        className="cursor-icon absolute w-8 h-8 bg-transparent rounded-full"
                        style={position}
                    />
                </div>
            </div>
        </div>
    );
}
