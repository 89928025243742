import React, { useEffect, useState } from "react";
// @ts-ignore
import popChangeImage from "../../screenshots.nosync/slides/NYC5_simple.jpg";
// @ts-ignore
import raceImage from "../../screenshots.nosync/slides/NYC4_simple.jpg";
// @ts-ignore
import incomeImage from "../../screenshots.nosync/slides/NYC3_simple.jpg";
// @ts-ignore
import ageImage from "../../screenshots.nosync/slides/NYC2_simple.jpg";
// @ts-ignore
import election2020Image from "../../screenshots.nosync/slides/NYC1_simple.jpg";
import { useMediaQuery } from "@mantine/hooks";
import { SegmentedControl } from "@mantine/core";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { createUseStyles } from "react-jss";

type DatasetName = "Race" | "Income" | "Age" | "Change" | "Election";

const datasetToImageMap: Record<DatasetName, string> = {
    Race: raceImage,
    Income: incomeImage,
    Age: ageImage,
    Change: popChangeImage,
    Election: election2020Image
};

const datasetNames: DatasetName[] = [
    "Race",
    "Income",
    "Age",
    "Change",
    "Election"
];

const useStyles = createUseStyles({
    mapContainer: {
        display: "flex",
        flexDirection: "row",
        // borderRadius: 10,
        overflow: "hidden",
        "@media (max-width: 767px)": {
            // marginBottom: 30,
            flexDirection: "column-reverse"
        }
    },
    mapTextBox: {
        width: "33.33%",
        maxWidth: 150,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        "@media (max-width: 767px)": {
            maxWidth: "none",
            width: "100%",
            marginTop: "27vh"
        },
        "& p": {
            display: "flex",
            alignItems: "center",
            paddingLeft: 20,
            fontSize: 18,
            fontWeight: 500,
            cursor: "pointer",
            height: "100%",
            // '@media (max-width: 767px)': {
            //     fontSize: 16,
            // },
            "&:hover": {
                fontWeight: 600
            }
        }
    },
    mapImageBox: {
        width: "66.66%",
        flex: 1,
        position: "relative",
        height: 400,
        "& img": {
            width: "100%",
            height: "100%",
            position: "absolute",
            userSelect: "none",
            pointerEvents: "none",
            // height: '100%',
            objectFit: "cover",
            // backgroundColor: "#F7F7F7",
            // backgroundOpacity: 0.5,
            borderTopLeftRadius: '0.25rem',
            overflow: 'hidden'
            
        },

        "@media (max-width: 767px)": {
            width: "100%",
            "& img": {
                height: "27vh",
                position: "absolute"
            }
        }
    },
    fadeEnter: {
        opacity: 0,
        zIndex: 1
    },
    fadeEnterActive: {
        opacity: 1,
        transition: "opacity 350ms ease-in"
    },
    fadeExit: {
        opacity: 1
    },
    fadeExitActive: {
        opacity: 0,
        transition: "opacity 350ms ease-in"
    }
});

export default function Carousel() {
    const styles = useStyles();
    const [userHasChanged, setUserHasChanged] = useState(false);
    const [selectedDataset, setSelectedDataset] = useState<DatasetName>("Race");
    const isMobile = useMediaQuery("(max-width: 760px)");

    const handleDatasetClick = (datasetName: DatasetName) => {
        setSelectedDataset(datasetName);
        setUserHasChanged(true);
    };

    useEffect(() => {
        if (userHasChanged) return;

        const intervalId = setInterval(() => {
            setSelectedDataset((prevSelected) => {
                const currentIndex = datasetNames.indexOf(prevSelected);
                const nextIndex = (currentIndex + 1) % datasetNames.length;
                return datasetNames[nextIndex];
            });
        }, 2500);

        return () => clearInterval(intervalId);
    }, [selectedDataset, userHasChanged]);

    return (
        <div className="landingPageSection w-full bg-gradient-to-br pb-0 from-red-100 to-orange-200">
            <p className="landingPageTitle text-center">
                Data visualization made easy
            </p>
            <p className="landingPageSubtitle text-center">
                Create beautiful maps in seconds, no GIS or coding required.
            </p>
            <div className={styles.mapContainer}>
                <div className={styles.mapTextBox}>
                    {isMobile ? (
                        <SegmentedControl
                            data={datasetNames.map((datasetName) => ({
                                label:
                                    datasetName === "Change"
                                        ? "Growth"
                                        : datasetName,
                                value: datasetName
                            }))}
                            value={selectedDataset}
                            onChange={handleDatasetClick}
                            color="blue"
                            style={{ background: 'rgba(255, 255, 255, 0.0)' }}
                            fullWidth
                        />
                    ) : (
                        datasetNames.map((datasetName) => (
                            <p
                                key={datasetName}
                                onClick={() => handleDatasetClick(datasetName)}
                                style={{
                                    color:
                                        selectedDataset === datasetName
                                            ? "rgb(0, 123, 255, 0.8)"
                                            : "rgb(29, 29, 29)",
                                }}
                                className="transition-all duration-300"
                            >
                                {datasetName === "Change"
                                    ? "Growth"
                                    : datasetName === "Election"
                                    ? "Election"
                                    : datasetName}
                            </p>
                        ))
                    )}
                </div>
                <TransitionGroup className={styles.mapImageBox}>
                    <CSSTransition
                        key={selectedDataset}
                        timeout={500}
                        classNames={{
                            enter: styles.fadeEnter,
                            enterActive: styles.fadeEnterActive,
                            exit: styles.fadeExit,
                            exitActive: styles.fadeExitActive
                        }}
                    >
                        <img
                            src={datasetToImageMap[selectedDataset]}
                            alt={`${selectedDataset} view colors`}
                            // className="opacity-50"
                        />
                    </CSSTransition>
                </TransitionGroup>
            </div>
        </div>
    );
}
