import React, { useState, useEffect, useCallback, useContext } from "react";
import { createUserWithEmailAndPassword, sendEmailVerification, reload } from "firebase/auth";
import { getApp } from "@firebase/app";
import styles from "./signup.module.css";
import {
    createCheckoutSession,
    getStripePayments
    // } from "@stripe/firestore-stripe-payments";
} from "@invertase/firestore-stripe-payments";
import { useLocation } from "react-router-dom";

import { TextInput, Button, Group, Box, PasswordInput, Text, rem, Checkbox, Stepper, NumberInput } from "@mantine/core";
import { Notifications, notifications } from "@mantine/notifications";
import { useForm } from "@mantine/form";
import { useMediaQuery } from "@mantine/hooks";

import { IconUserCheck, IconMailOpened, IconShieldCheck } from "@tabler/icons-react";
import { FiUser, FiUsers, FiAlertTriangle } from "react-icons/fi";

import { setDoc, doc, getFirestore } from "firebase/firestore";
import { v4 as uuidv4 } from "uuid";
// import useAppSettings from "../hooks/useAppSettings";
import { getFirebase } from "../lib/firebase";
import { AuthContext } from "../App";
import useUserRole from "../hooks/useUserRole";
import { PRICES } from "../prices";

function getErrorMessage(code) {
    console.warn(code);
    switch (code) {
        case "auth/invalid-email":
            return "Invalid email address. Please enter a valid email.";
        case "auth/email-already-exists":
            return "This email address is already in use.";
        case "auth/user-disabled":
            return "This account has been disabled. Please contact support for assistance.";
        case "auth/operation-not-allowed":
            return "Account creation is currently not allowed. Please try again later.";
        case "auth/weak-password":
            return "Password too weak. Must be at least 6 characters.";
        default:
            return "An error occurred. Please try again.";
    }
}

export function SignUp() {
    const [active, setActive] = useState(0);
    const [paymentLoading, setPaymentLoading] = useState(false);
    const isMobile = useMediaQuery("(max-width: 50em)");
    // const { getStripeRole, firestore: db } = useAppSettings();
    // const { firestore: db } = useAppSettings();
    const db = getFirestore();
    // const stripeRole = getStripeRole();
    const { auth } = getFirebase();
    const { user, loading: authLoading } = useContext(AuthContext);
    const { role: stripeRole, loading: roleLoading } = useUserRole(user?.uid, user?.email);

    console.log("role", stripeRole, auth.user);

    const location = useLocation();
    // const isCommercial = location.pathname === "/signup/commercial";
    const [isCommercial, setIsCommercial] = useState(location.pathname.startsWith("/signup/commercial"));
    const [isYearly, setIsYearly] = useState(location.pathname.endsWith("yearly"));

    const checkIfUser = useCallback(() => {
        if (authLoading || roleLoading) {
            // if loading, set a timeout to trigger a re-render after 1 second
            setTimeout(checkIfUser, 1000);
            return;
        }

        console.log("User is signed in");
        console.log("Checking if email is verified: ", stripeRole, user);
        if (user.emailVerified || stripeRole) {
            console.log("Email is verified", stripeRole);
            if (stripeRole) {
                if ((stripeRole !== "Organization" && isCommercial) || stripeRole === "None") {
                    setActive(2);
                } else {
                    console.log("Account payed for. Stripe role:", stripeRole);
                    setActive(3);
                }
            } else {
                console.log("Account not payed for. Stripe role:", stripeRole);
                setActive(2);
            }
        } else {
            console.log("Email not verified yet");
            setActive(1);
        }
    }, [isCommercial, stripeRole, user, authLoading, roleLoading]);

    // const checkIfUser = useCallback(() => {
    //     const user = auth.currentUser;
    //     if (!user) {
    //         return;
    //     }

    //     console.log("User is signed in");
    //     // setEmail(user.email);

    //     console.log("Checking if email is verified: ", stripeRole, user);
    //     if (user.emailVerified || stripeRole) {
    //         console.log("Email is verified", stripeRole);
    //         if (stripeRole) {
    //             if (stripeRole !== 'Organization' && isCommercial) {
    //                 setActive(2);
    //             } else {
    //                 console.log("Account payed for. Stripe role:", stripeRole);
    //                 setActive(3);
    //             }
    //         } else {
    //             console.log("Account not payed for. Stripe role:", stripeRole);
    //             setActive(2);
    //         }
    //     } else {
    //         console.log("Email not verified yet");
    //         setActive(1);
    //     }
    // }, [isCommercial, stripeRole, auth]);

    useEffect(() => {
        console.log("Checking if user is already signed in");
        checkIfUser();
    }, [checkIfUser]);

    useEffect(() => {
        console.log("stripeRole changed", stripeRole);
        checkIfUser();
    }, [stripeRole, checkIfUser]);

    useEffect(() => {
        console.log("active changed", active);

        const user = auth.currentUser;
        if (active === 1) {
            const checkInterval = setInterval(() => {
                reload(user).then(() => {
                    console.log("checking if email is verified: ", user.emailVerified);
                    if (user.emailVerified) {
                        console.log("Email is verified");
                        console.log("stripeRole", stripeRole);
                        if (stripeRole && stripeRole !== "None" && stripeRole !== "Organization" && !isCommercial) {
                            setActive(3);
                        } else {
                            setActive(2);
                        }
                        clearInterval(checkInterval);
                    } else {
                        console.log("Email not verified yet");
                    }
                });
            }, 3000);
        }
    }, [active, stripeRole, auth, isCommercial]);

    const createAccount = (values) => {
        console.log(values.email, values.password);
        createUserWithEmailAndPassword(auth, values.email, values.password)
            .then(async (userCredential) => {
                // Account creation successful.
                const user = userCredential.user;
                sendEmailVerification(user);
                if (values.newsletter) {
                    const emailId = uuidv4();
                    await setDoc(doc(db, "emails", emailId), {
                        email: values.email,
                        uid: user.uid,
                        timestamp: new Date().toLocaleString("en-US", {
                            timeZone: "America/Chicago"
                        })
                    });
                }
                setActive(1);
                notifications.show({
                    title: "Verification Email Sent",
                    message: `Verification Email has been sent, please check your inbox.`,
                    color: "green",
                    withBorder: true
                });
            })
            .catch((error) => {
                registerForm.setErrors({ email: `${getErrorMessage(error.message)}` });
            });
    };

    const resendEmail = () => {
        const user = auth.currentUser;
        sendEmailVerification(user)
            .then(() => {
                notifications.show({
                    title: "Verification Email Sent",
                    message: `Verification Email has been sent, please check your inbox.`,
                    color: "green",
                    withBorder: true
                });
            })
            .catch((error) => {
                notifications.show({
                    title: "Error",
                    message: `An error occurred while resending the verification email: ${error.message}`,
                    color: "red",
                    withBorder: true
                });
                console.error("An error occurred while resending the verification email.", error);
            });
    };

    const registerForm = useForm({
        initialValues: {
            email: "",
            password: "",
            confirmPassword: "",
            agreeToTOS: false,
            newsletter: false
        },

        validate: {
            email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
            confirmPassword: (value, values) => (values.password === value ? null : "Passwords do not match"),
            agreeToTOS: (value) => (value ? null : "You must agree to the Terms of Service")
        }
    });

    const handlePayment = async (values) => {
        console.log("handlePayment", values, auth.currentUser);

        setPaymentLoading(true);
        try {
            const app = getApp();
            const payments = getStripePayments(app, {
                productsCollection: "products",
                customersCollection: "customers"
            });

            console.log(Number(isCommercial ? values.userCount : 1));

            if (isCommercial) {
                try {
                    const emailId = uuidv4();
                    await setDoc(doc(db, "organizations", emailId), {
                        email: auth.currentUser.email,
                        organization: values.organization,
                        initialQuantity: Number(values.userCount),
                        timestamp: new Date().toLocaleString("en-US", {
                            timeZone: "America/Chicago"
                        })
                    });
                } catch (error) {
                    console.error("Error adding document for organization name: ", error);
                }
            }

            let priceId;

            if (isCommercial) {
                priceId = isYearly ? "price_1Oej5iIXhZVHUYv9EhXTSBld" : "price_1Oej6GIXhZVHUYv9uGNjH6O0";
            } else {
                priceId = isYearly ? "price_1Oej4HIXhZVHUYv90Bv2o9YO" : "price_1Oej3JIXhZVHUYv9kfMZDi6B";
            }

            const session = await createCheckoutSession(payments, {
                price: priceId,
                // price: isCommercial
                //     ? "price_1NsxR3IXhZVHUYv9wBH4A9EV" // $16/User/month
                //     : "price_1Nn5wXIXhZVHUYv9NWScwBRs", // $8/Month
                quantity: Number(isCommercial ? values.userCount : 1), // set quantity based on the number of users for commercial
                success_url: "https://redistricter.com/signout",
                cancel_url: window.location.origin
            });
            window.location.assign(session.url);
        } catch (error) {
            notifications.show({
                title: "Error",
                message: `A payment error occured: ${error.message}`,
                color: "red",
                withBorder: true
            });
            console.error("A payment error occured.", error);
            setPaymentLoading(false);
            //   setError(error.message);
        }
    };

    const payForm = useForm({
        initialValues: {
            organization: "",
            userCount: 1
        },

        validate: {
            // email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
            // confirmPassword: (value, values) => (values.password === value ? null : 'Passwords do not match'),
            // agreeToTOS: (value) => (value ? null : 'You must agree to the Terms of Service'),

            // organization: (value) => (isCommercial && !value ? 'Organization name is required' : null),
            userCount: (value) =>
                isCommercial && (value < 1 || value > 99) ? "User count must be between 1 and 99" : null
        }
    });

    /** If a user already has an individual subscription */
    const hasIndividual = !stripeRole || stripeRole === "None" || stripeRole === "Organization";

    return (
        <div className={styles.container}>
            <div className={styles.stepper}>
                <Stepper
                    active={active}
                    onStepClick={setActive}
                    orientation={isMobile ? "horizontal" : "vertical"}
                    size={isMobile ? "xs" : "md"}
                    allowNextStepsSelect={false}
                >
                    <Stepper.Step
                        label="First step"
                        description="Create an account"
                        icon={<IconUserCheck style={{ width: rem(18), height: rem(18) }} />}
                        styles={{
                            stepIcon: { color: "red", outline: "1px solid green" }
                        }}
                    >
                        {/* Step 1 content: Create an account */}
                    </Stepper.Step>
                    <Stepper.Step
                        label="Second step"
                        description="Verify email"
                        icon={<IconMailOpened style={{ width: rem(18), height: rem(18) }} />}
                        loading={active === 1}
                    >
                        {/* Step 2 content: Verify email */}
                    </Stepper.Step>
                    <Stepper.Step
                        label="Final step"
                        description="Get full access"
                        icon={<IconShieldCheck style={{ width: rem(18), height: rem(18) }} />}
                    >
                        {/* Step 3 content: Get full access */}
                    </Stepper.Step>

                    <Stepper.Completed>{/* Completed, click back button to get to previous step */}</Stepper.Completed>
                </Stepper>
            </div>
            <div className={styles.main}>
                <Notifications style={{ position: "absolute", top: "50px", height: "100px", left: "10px" }} />
                <Box maw={350} mx="auto" className={styles.box}>
                    {active === 0 && (
                        <form onSubmit={registerForm.onSubmit((values) => createAccount(values))}>
                            <Text size="xl" className={styles.title}>
                                Sign up
                            </Text>

                            <TextInput
                                withAsterisk
                                label="Email"
                                placeholder="your@email.com"
                                error={registerForm.errors.email}
                                {...registerForm.getInputProps("email")}
                            />

                            <PasswordInput
                                withAsterisk
                                label="Password"
                                style={{ marginTop: "5px" }}
                                {...registerForm.getInputProps("password")}
                            />

                            <PasswordInput
                                withAsterisk
                                label="Confirm Password"
                                style={{ marginTop: "5px" }}
                                {...registerForm.getInputProps("confirmPassword")}
                            />

                            <Checkbox
                                mt="md"
                                label={
                                    <>
                                        I agree to the{" "}
                                        <a href="/tos" className={styles.link}>
                                            Terms of Service
                                        </a>
                                    </>
                                }
                                {...registerForm.getInputProps("agreeToTOS", { type: "checkbox" })}
                            />

                            <Checkbox
                                mt="md"
                                label="Sign up for the Redistricter newsletter"
                                {...registerForm.getInputProps("newsletter", { type: "checkbox" })}
                            />

                            <Group justify="flex-end" mt="md">
                                <Button type="submit" style={{ width: "100%" }}>
                                    Sign up
                                </Button>
                            </Group>

                            <Text size="sm" style={{ marginTop: "10px" }}>
                                Already have an account?{" "}
                                <a href="/signin" className={styles.link}>
                                    Sign in
                                </a>
                            </Text>
                        </form>
                    )}
                    {active === 1 && (
                        <>
                            <Text size="xl" className={styles.title}>
                                Verification email sent
                            </Text>
                            <Text size="sm">
                                Verification email sent to {registerForm.values.email}. Please check your inbox.
                            </Text>
                            <Text size="sm" style={{ marginTop: "10px" }}>
                                Didn't get an email?{" "}
                                <span onClick={resendEmail} className={styles.link}>
                                    Resend the verification email.
                                </span>
                            </Text>
                        </>
                    )}
                    {active === 2 && (
                        <form onSubmit={payForm.onSubmit((values) => handlePayment(values))}>
                            {stripeRole && stripeRole !== "None" && stripeRole !== "Organization" ? (
                                <Text size="xl" className={styles.title}>
                                    Upgrade Plan
                                </Text>
                            ) : (
                                <>
                                    <Text size="xl" className={styles.title}>
                                        Payment
                                    </Text>
                                    <Text
                                        size="md"
                                        style={{ marginBottom: "15px", fontWeight: "600", textAlign: "center" }}
                                    >
                                        Subscription type
                                    </Text>
                                </>
                            )}

                            <div style={{ marginLeft: "auto", marginRight: "auto", marginBottom: 10 }}>
                                <Button
                                    variant={!isYearly ? "filled" : "light"}
                                    radius="md"
                                    style={{
                                        width: "calc(50% - 10px)",
                                        marginRight: 10
                                    }}
                                    onClick={() => setIsYearly(false)}
                                >
                                    Monthly
                                </Button>
                                <Button
                                    variant={isYearly ? "filled" : "light"}
                                    radius="md"
                                    style={{
                                        width: "calc(50% - 10px)",
                                        marginLeft: 10
                                    }}
                                    onClick={() => setIsYearly(true)}
                                >
                                    Yearly
                                </Button>
                            </div>

                            <div className={styles.priceBoxes}>
                                {hasIndividual && (
                                    <div
                                        className={!isCommercial ? styles.priceBoxSelected : styles.priceBox}
                                        onClick={() => setIsCommercial(false)}
                                    >
                                        <Text size="md" className={styles.title} style={{ marginBottom: "5px" }}>
                                            Individual
                                        </Text>
                                        <FiUser className={styles.priceIcon} />
                                        <Text
                                            size="sm"
                                            style={{ marginTop: "5px", textAlign: "center", fontWeight: "600" }}
                                        >
                                            {/* ${PRICES.individual.monthly}/mo */}$
                                            {isYearly ? PRICES.individual.yearly : PRICES.individual.monthly}/
                                            {isYearly ? "yr" : "mo"}
                                        </Text>
                                    </div>
                                )}
                                <div
                                    className={isCommercial ? styles.priceBoxSelected : styles.priceBox}
                                    onClick={() => setIsCommercial(true)}
                                >
                                    <Text size="md" className={styles.title} style={{ marginBottom: "5px" }}>
                                        Commercial
                                    </Text>
                                    <FiUsers className={styles.priceIcon} />
                                    <Text
                                        size="sm"
                                        style={{ marginTop: "5px", textAlign: "center", fontWeight: "600" }}
                                    >
                                        {/* ${PRICES.commercial.yearly}/user/mo */}$
                                        {isYearly ? PRICES.commercial.yearly : PRICES.commercial.monthly}/user/
                                        {isYearly ? "yr" : "mo"}
                                    </Text>
                                </div>
                            </div>

                            {!isCommercial && (
                                <div className={styles.warning}>
                                    <FiAlertTriangle className={styles.warningIcon} />
                                    <Text size="xs">
                                        This subscription is not licensed for commercial, government, or other official
                                        purposes.
                                    </Text>
                                </div>
                            )}

                            {/* <div className={styles.warning}>
                                <Text size="xs">
                                    Warning for new users after November 17, there is an <a href="https://twitter.com/Redistricter/status/1725616163252473909">ongoing issue</a> with 
                                    payments not being processed correctly. There will be a several hours delay before your account is activated after you pay. I'm working to resolve the issue ASAP.
                                    Sorry about that.
                                </Text> 
                            </div> */}

                            {isCommercial && (
                                <>
                                    <TextInput
                                        // withAsterisk
                                        label="Organization"
                                        placeholder="Organization Name (if any)"
                                        // error={payForm.errors.email}
                                        {...payForm.getInputProps("organization")}
                                        style={{ marginTop: "5px" }}
                                    />

                                    <NumberInput
                                        withAsterisk
                                        label="Number of users"
                                        min={1}
                                        max={99}
                                        style={{ marginTop: "5px" }}
                                        {...payForm.getInputProps("userCount")}
                                    />
                                    {/* <Text size="lg" style={{ marginTop: '15px', textAlign: 'left', fontWeight: '600' }}>
                                        ${payForm.values.userCount * 8}/month
                                    </Text> */}
                                </>
                            )}

                            <Group justify="flex-end" mt="md">
                                <Button
                                    type="submit"
                                    style={{ width: "100%" }}
                                    onClick={
                                        // handlePayment
                                        () => handlePayment(payForm.values)
                                    }
                                    loading={paymentLoading}
                                >
                                    Finish payment
                                </Button>
                            </Group>
                            {isCommercial && stripeRole && stripeRole !== "None" && stripeRole !== "Organization" && (
                                <Text size="sm" style={{ marginTop: "10px", color: "gray" }}>
                                    If you're upgrading from the individual plan you will be refunded the prorated cost
                                    of your current subscription within a week of your upgrade.
                                </Text>
                            )}
                        </form>
                    )}
                    {active === 3 && (
                        <>
                            <Text size="xl" className={styles.title}>
                                Account creation completed
                            </Text>
                            <Text size="sm">
                                Your account has been created and payment processed. You're ready to start using
                                Redistricter!
                            </Text>
                            <Text size="sm" style={{ marginTop: "10px" }}>
                                <a href="/maps" className={styles.link}>
                                    Get started on the maps page
                                </a>{" "}
                                or read the{" "}
                                <a href="https://docs.redistricter.com/guide/quickstart" className={styles.link}>
                                    quickstart guide
                                </a>
                                .
                            </Text>
                            {stripeRole === "Organization" && (
                                <Text size="sm" style={{ marginTop: "10px" }}>
                                    You can start using this account immediately, and I will contact you within the next
                                    24 hours to set up the remainder of your organization's accounts, if you purchased
                                    more than one account.
                                </Text>
                            )}
                            {stripeRole !== "Organization" && (
                                <Text size="sm" style={{ marginTop: "10px" }}>
                                    Click{" "}
                                    <a href="/signup/commercial" className={styles.link}>
                                        here to upgrade
                                    </a>{" "}
                                    to a commercial tier subscription.
                                </Text>
                            )}
                            <Text size="sm" style={{ marginTop: "10px" }}>
                                Or click{" "}
                                <a href="switchToYearly" className={styles.link}>
                                    here to switch
                                </a>{" "}
                                to yearly billing if you haven't already.
                            </Text>
                        </>
                    )}
                </Box>
            </div>
        </div>
    );
}
