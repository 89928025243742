export const calculateDaysRemaining = (expiryDate: any): number => {
    const current: any = new Date();
    const expiry: any = new Date(expiryDate);
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    return Math.round((expiry - current) / oneDay);
};

export const formatMoney = (amount: number | null): string => {
    if (amount === null || isNaN(amount)) {
        return "$0";
    }

    if (amount >= 1e9) {
        return "$" + (amount / 1e9).toFixed(2) + "B";
    }
    if (amount >= 1e6) {
        return "$" + (amount / 1e6).toFixed(2) + "M";
    }

    try {
        return "$" + amount.toFixed(0).replace(/\d(?=(\d{3})+$)/g, "$&,");
    } catch (e) {
        return "$" + amount;
    }
};

export const formatPopulation = (amount: number): string => {
    const roundedAmount = Math.round(amount);
    return new Intl.NumberFormat("en-US").format(roundedAmount);
};
