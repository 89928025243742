import React from "react";
import styles from "./tos.module.css";

const fieldKeys = [
    {
        name: "Elections (ex: G20PreD)",
        description: `
        Every field that starts with 'G' corresponds to the number of votes a party received in an election. 
        For example, the field 'G20PreD' would contain the number of votes Joe Biden won in 2020, where '20' is the year (2020), 
        'Pre' refers to the presidential election, and 'D' refers to Democrats.
        
        Some multiparty elections may have a party with the code "All". This refers to the total number of votes in that election.
        `
    },
    {
        name: "Simulated Elections (GBuildD and GBuildR)",
        description: `
        Not available for all datasets and geographies.
        GBuildD and GBuildR refer to the number of votes for democrats/republicans in a simulated election created by the user.
        Only shows up in district data exported from Redistricter after a user has simulated an election.
        `
    },
    {
        name: "Pop20",
        description: "2020 population"
    },
    {
        name: "2020 population by race (ex: DAsian)",
        description: "The number of people of a specific race (from the 2020 census)."
    },
    {
        name: "DVAP",
        description: "2020 voting age population"
    },
    {
        name: "Voting age population by race (ex: DWhiteVAP)",
        description: "The number of voting age people of a specific race."
    },
    {
        name: "Pre-2020 population figures (ex: DPop10)",
        description:
            "Population data from before 2020 where the last two numbers indicate the year (DPop90 = 1990 population, DPop10 = 2010 population, etc.)"
    },
    {
        name: "Pre-2020 population by race (ex: D10White)",
        description: "Same as above but formatted as D{year}{race}"
    },
    {
        name: "Ancestry (ex: DA_Japan)",
        description: `
        The number of people with ancestry from a country or collection of countries. 
        For example, the field 'DA_Japan' would contain the number of people with ancestry from Japan.
        The "DA" means it's part of the asian ancestry group (DL is latino, DE is white, DF is african)
        `
    },
    {
        name: "DVeteran",
        description: "The number of people who are veterans."
    },
    {
        name: "DNonVet or DCivilian",
        description: "The number of people who are not veterans."
    },
    {
        name: "Sex (ex: DMale or DFemale)",
        description: "The number of people who are male or female."
    },
    {
        name: "DArea",
        description: `Total area in square kilometers divided by 100. Example: if a feature has a DArea of 1,000, it has an area of 10 square kilometers.`
    },
    {
        name: "DGINI",
        description: `Used to calculate the GINI index of the feature.
        To get an accurate reading of a feature's GINI index, divide the DGINI by the feature's population (DPop20).`
    },
    {
        name: "Methods of commute (ex: DCCar)",
        description: `
        The number of people who commute to work by a certain method of transporation (DCCar is car for example).
        Includes all fields that start with "DC"
        `
    },
    {
        name: "Industry of work (ex: DIWholesale)",
        description: `
        The number of people who work in a given industry (DIWholesale is wholesale for example).
        Includes all fields that start with "DI".`,
        listSubfields: [
            "DIAgri=Agriculture, forestry, fishing and hunting, and mining",
            "DIConst=Construction",
            "DIMfg=Manufacturing",
            "DIWholesale=Wholesale trade",
            "DIRetail=Retail",
            "DITrans=Transportation and warehousing, and utilities",
            "DIInfo=Information",
            "DIProf=Professional, scientific, management, and administrative and waste management services",
            "DIEdu=Educational services, and health care and social assistance",
            "DIArts=Arts, entertainment, and recreation, and accommodation and food services",
            "DIOther=Other services (except public administration)",
            "DIPa=Public administration"
        ]
    },
    {
        name: "DinState and DoutState",
        description: `
        The population who work in state and out of state.`
    },
    {
        name: "DinCounty and DoutCounty",
        description: `
        The population who work in county and out of county.`
    },
    {
        name: "DHouseholds",
        description: `
        The number of households.`
    },
    {
        name: "DTotalIncome",
        description: `
        The total income of all households. To get the average income per household, divide by the number of households (DHouseholds).`
    },
    {
        name: "Household Income Distributions (ex: D0_25k)",
        description: `
        The number of households in a certain income bracket. 
        For example, the field 'D0_25k' would contain the number of households making $0 to $25,000.`
    },
    {
        name: "DCapitaIncome",
        description: `
        The total income of residents. Divide by 2020 population (DPop20) to get the average income per person.`
    },
    {
        name: "D10PCI and D00PCI",
        description: `
        The total income of residents from 2010 (D10PCI) and 2000 (D00PCI).
        Divide by the relevant population (DPop10 for 2010 or DPop00 for 2000) to get the average income per person.`
    },
    {
        name: "DUSBorn",
        description: `
        The population who are born in the US.`
    },
    {
        name: "DInStateB and DOutStateB",
        description: `
        The population who are born in-state (DInStateB) and out-of-state (DOutStateB).`
    },
    {
        name: "DForeignBorn",
        description: `
        The population who were born outside the US.`
    },
    {
        name: "DForeignC and DForeignNC",
        description: `
        The population who were born outside the US but are citizens (DForeignC) and the population who were born outside the US and are not citizens (DForeignNC).`
    },
    {
        name: "DStSpouse and DGaySpouse",
        description: `
        The population who are married to a straight or gay spouse.`
    },
    {
        name: "DStPartner and DGayPartner",
        description: `
        The population who are living with a straight or gay partner.`
    },
    {
        name: "Age fields (ex: D20Minus or D20to40)",
        description: `
        Represents the number of people who are in a certain age group. 
        For example, the field 'D20Minus' would contain the number of people who are 20 years old or younger, 
        the field 'D20to40' would contain the number of people who are 20 to 40 years old,
        and the field 'D65Plus' would contain the number of people who are 65 years old or older.`
    },
    {
        name: "DSumAge",
        description: `
        The total age of all residents. To get the average age, divide by the population (DPop20).`
    },
    {
        name: "DTotal_Rent",
        description: `
        The total rent of all houses. To get the average rent, divide by the number of households (DHouseholds).`
    },
    {
        name: "DTotalCom",
        description: `
        The total one-way commute time of all commuters. To get the average commute time, divide by the number of 16+ workers (DWorker_16_up).`
    },
    {
        name: "DEnglishOnly",
        description: `
        The population that speak only English.`
    },
    {
        name: "DLimitedEnglish",
        description: `
        The population that speak limited or no English.`
    },
    {
        name: "DSpanish",
        description: `
        The population that speak Spanish.`
    },
    {
        name: "DIndoEuropean",
        description: `
        The population that speak Indo-European languages.`
    },
    {
        name: "DAsianPacific",
        description: `
        The population that speak Asian or Pacific languages.`
    },
    {
        name: "DOtherLanguage",
        description: `
        The population that speak a language from a group not listed above.`
    },
    {
        name: "DHighschool",
        description: `
        The number of people who have a highschool degree.
        The best way to calculate the population that hasn't completed highschool is to subtract the number of people who have a highschool degree from the total population over 25 (D25_or_older) or the total population over 18 (DVAP). Redistricter uses D25_or_older to calculate this.`
    },
    {
        name: "DHighschool",
        description: `
        The number of people who have a highschool degree.`
    },
    {
        name: "DBachelors",
        description: `
        The number of people who have a bachelor's degree.`
    },
    {
        name: "DGraduate",
        description: `
        The number of people who have a graduate degree.`
    },
    {
        name: "DNonInstit",
        description: `
        The number of people who are not institutionalized
        (The census counts prisoners, hospice/mental institution patients and other groups as being institutionalized).`
    },
    {
        name: "DInLF",
        description: `
        The number of people who are in the labor force.`
    },
    {
        name: "DPopPov",
        description: `
        Total population for whom poverty status is determined`
    },
    {
        name: "DPoverty",
        description: `
        Total population for whom poverty status is determined and in poverty`
    },
    {
        name: "DInsured and DUninsured",
        description: `
        The number of people who have health insurance and the number of people who are not insured.
        Only includes people who are not institutionalized.`
    },
    {
        name: "DOwner and DRenter",
        description: `
        The population who own their home and the population who rent their home.`
    },
    {
        name: "DOc",
        description: `
        The total number of housing structure units.`
    },
    {
        name: "Housing structure units distribution (ex: DOc1Det)",
        description: `
        The number of housing structure units in a certain distribution.         
        Includes all fields that start with "DOc".
        `,
        listSubfields: [
            "DOc1Det=1 unit detached",
            "DOc1Att=1 unit attached",
            "DOc2_10=2 to 10 units",
            "DOc10_50=10 to 19 units",
            "DOc50_Plus=50 or more units"
        ]
    },
    {
        name: "DWorker_16_up",
        description: `
        The number of workers over the age of 16.`
    },
    {
        name: "HOUSING20",
        description: `
        The number of housing units.`
    },
    {
        name: "CX and CY",
        description: `
        Not available for all datasets and geographies. 
        They represent the coordinates of the centroid of the feature it belongs to where CY is the latitude and CX is the longitude.
        `
    },
    {
        name: "COUNTYFP",
        description: `
        Not available for all datasets and geographies. 
        The unique code to identify the county.
        `
    },
    {
        name: "CNTYVTD",
        description: `
        Not available for all datasets and geographies. In Block Groups it is the same as the GEOID.
        The unique code to identify the block group, precinct, or census block.
        `
    }
];

export function DataDictionary() {
    return (
        <div className={styles.main}>
            <h1>Raw Data Dictionary</h1>
            <p style={{ lineHeight: 1.5 }}>
                Below are the definition of fields within Redistricter's raw data. Raw data is available for districts
                through a commercial-tier subscription and it's available for precincts, block groups, census blocks,
                and other geographies via a{" "}
                <a className={styles.link} href="/quote">
                    data license
                </a>
                .
            </p>

            {/* display: 'inline-block' */}
            {fieldKeys.map((field, index) => (
                <div key={index} style={{ marginTop: 25 }}>
                    <b>{field.name}</b>
                    <p style={{ marginTop: 5, marginBottom: 5, lineHeight: 1.5 }}>{field.description}</p>
                    {field.listSubfields && (
                        <ul className={styles.industryList}>
                            {field.listSubfields.map((subfield, i) => {
                                const [code, name] = subfield.trim().split("=");
                                return (
                                    <li key={i}>
                                        <b>{code}:</b> {name}
                                    </li>
                                );
                            })}
                        </ul>
                    )}
                </div>
            ))}

            <h2>Contact</h2>
            <p>
                If you have any questions feel free to email me at{" "}
                <a className={styles.link} href="mailto:colin@redistricter.com">
                    colin@redistricter.com
                </a>
                .
            </p>
        </div>
    );
}
