import React from "react";
import styles from "./tos.module.css";
import { Helmet } from "react-helmet";

export function About() {
    return (
        <>
            <Helmet>
                <title>About Redistricter</title>
                <meta name="description" content="About me and why I made Redistricter" />
            </Helmet>
            <div className={styles.main} style={{ lineHeight: "1.5" }}>
                <h1>About Me</h1>
                <p>
                    My name is Colin Miller and I was a student at the University of Portland studying computer science
                    with an interest in politics, data, and maps. In 2021 I made (what I now call){" "}
                    <a className={styles.link} href="https://old.redistricter.com">
                        Old Redistricter (now defunct)
                    </a>{" "}
                    as my first big web development project. After that I had the idea for (new) Redistricter which I
                    spent many hundreds of hours working on in between classes for several months as a passion project.
                </p>

                <p>
                    After releasing Redistricter it took off far faster than I expected, and I decided to take a
                    semester off of school to work on this. I didn't make Redistricter to make money, but now that it is
                    I'm very grateful for the opportunity to work on this full time. I've since moved to NYC to continue
                    working on Redistricter.
                </p>

                <h2>Data Credits (not a complete list)</h2>

                <li>
                    <a className={styles.link} href="https://twitter.com/vest_team">
                        VEST
                    </a>{" "}
                    for some of the election data
                </li>
                <li>
                    <a className={styles.link} href="https://www.census.gov/">
                        The Census/American Community Survey
                    </a>{" "}
                    for most of the demographic data
                </li>
                <li style={{ marginBottom: "20vh" }}>
                    Thanks to the NHGIS for 1990, 2000, and 2010 census data. Citation: Steven Manson, Jonathan
                    Schroeder, David Van Riper, Tracy Kugler, and Steven Ruggles. IPUMS National Historical Geographic
                    Information System: Version 17.0 [dataset]. Minneapolis, MN: IPUMS. 2022.
                    http://doi.org/10.18128/D050.V17.0
                </li>
            </div>
        </>
    );
}
