import React from "react";
import { signInWithEmailAndPassword, sendPasswordResetEmail, sendEmailVerification } from "firebase/auth";
import styles from "./signin.module.css";
import { useNavigate } from "react-router-dom";
import { TextInput, Button, Group, Box, PasswordInput, Text } from "@mantine/core";
import { Notifications, notifications } from "@mantine/notifications";
import { useForm } from "@mantine/form";
import { getFirebase } from "../lib/firebase";

export function SignIn() {
    const navigate = useNavigate();
    const { auth } = getFirebase();

    const signInWithEmail = (values) => {
        signInWithEmailAndPassword(auth, values.email, values.password)
            .then((userCredential) => {
                // Sign-in successful. Send email verification.
                const user = userCredential.user;
                if (!user.emailVerified) {
                    sendEmailVerification(user)
                        .then(() => {
                            notifications.show({
                                title: "Verification Email Sent",
                                message: `Verification Email has been sent, please check your inbox.`,
                                color: "green",
                                withBorder: true
                            });
                        })
                        .catch((error) => {
                            if (error.code === "auth/wrong-password") {
                                form.setErrors({ password: `${getErrorMessage(error.code)}` });
                            } else {
                                form.setErrors({ email: `${getErrorMessage(error.code)}` });
                            }
                        });
                }
                navigate("/");
            })
            .catch((error) => {
                if (error.code === "auth/user-not-found" || error.code === "auth/invalid-email") {
                    form.setErrors({ email: `${getErrorMessage(error.code)}` });
                } else if (error.code === "auth/wrong-password") {
                    form.setErrors({ password: `${getErrorMessage(error.code)}` });
                } else if (error.code === "auth/invalid-credential") {
                    form.setErrors({ email: `${getErrorMessage(error.code)}` });
                }
            })
            .finally(() => {
                // setEmail("");
                // setPassword("");
            });
    };

    const forgotPassword = () => {
        const email = form.getInputProps("email").value;

        if (email === "") {
            form.setErrors({ email: "Enter an email to reset your password" });
        } else {
            sendPasswordResetEmail(auth, email)
                .then(() => {
                    notifications.show({
                        title: "Reset email sent",
                        message: `Password reset email has been sent, please check your inbox.`,
                        color: "green",
                        withBorder: true
                    });
                })
                .catch((error) => {
                    form.setErrors({ email: `${getErrorMessage(error.code)}` });
                });
        }
    };

    function getErrorMessage(code) {
        switch (code) {
            case "auth/user-not-found":
                return "User does not exist.";
            case "auth/wrong-password":
                return "Incorrect password.";
            case "auth/user-disabled":
                return "User account has been disabled.";
            case "auth/invalid-email":
                return "Invalid email.";
            case "auth/missing-email":
                return "Please enter an email to reset your password.";
            case "auth/invalid-credential":
                return "Invalid credentials.";
            default:
                return "An error occurred. Please try again.";
        }
    }

    const form = useForm({
        initialValues: {
            email: "",
            password: ""
        },

        validate: {
            email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email")
        }
    });

    return (
        <div className={styles.main}>
            <Notifications style={{ position: "absolute", top: "80px", height: "100px" }} />
            <Box maw={350} mx="auto" className={styles.box}>
                <form onSubmit={form.onSubmit((values) => signInWithEmail(values))}>
                    <Text size="xl" className={styles.title}>
                        Sign in
                    </Text>

                    <TextInput
                        withAsterisk
                        label="Email"
                        placeholder="your@email.com"
                        error={form.errors.email}
                        {...form.getInputProps("email")}
                    />

                    <PasswordInput
                        withAsterisk
                        label="Password"
                        style={{ marginTop: "5px" }}
                        {...form.getInputProps("password")}
                    />

                    <Text size="sm" style={{ marginTop: "2px" }} className={styles.link} onClick={forgotPassword}>
                        Forgot password?
                    </Text>

                    <Group justify="flex-end" mt="md">
                        <Button type="submit" style={{ width: "100%" }}>
                            Sign in
                        </Button>
                    </Group>

                    <Text size="sm" style={{ marginTop: "10px" }}>
                        Don't have an account?{" "}
                        <a href="/signup" className={styles.link}>
                            Sign up
                        </a>
                    </Text>
                </form>
            </Box>
        </div>
    );
}

export function SignOut() {
    const { auth } = getFirebase();
    return auth.currentUser && <button onClick={() => auth.signOut()}>Sign Out</button>;
}
